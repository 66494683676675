/* eslint-disable */
import msalClient from 'Config/MSALClient';

const url = (workspaceId) => `https://api.powerbi.com/v1.0/myorg/groups/${workspaceId}/reports`;

async function getWorkspaces(workspaceId, { onSuccess, onError }) {
  try {
  const { value } = await msalClient(url(workspaceId), {});
  return onSuccess(value);
  } catch(error) {
    onError(error);
  }
}

export default getWorkspaces;
