/* eslint-disable */

/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import routerMap from 'app/Main/RouterMap';


function SignIn() {
  return null;
}

export default SignIn;
