import http from 'common/utils/Http';
import config from './index';

const baseURL = config.get('configAPI');
export default async function apiClient(url, params) {
  const options = {
    ...params,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (params && params.body) {
    options.body = JSON.stringify(params.body);
  }
  const response = await http(`${baseURL}plugins/power_bi/${url}`, options);
  return response;
}
