import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import cx from 'classnames';
import { getAccessToken } from 'Config/MSALClient';
import GetReportForTile from 'app/Services/TileReport/getForTile';
import { useRequest } from 'common/utils/Request';
import RequestStatus from 'common/components/RequestStatus';
import { string } from 'prop-types';
import './styles.scss';

const ReportDisplay = ({ tileId }) => {
  const [access, setAccess] = useState(null);
  const getTileReportRequest = useRequest(GetReportForTile);

  useEffect(() => {
    getTileReportRequest.start({ tileId });
    setAccess(getAccessToken());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // ToDo check hardcoded height
  return (
    <div
      className={cx(
        'report-display-container',
        !getTileReportRequest.response && 'pt-2',
      )}
    >
      <RequestStatus
        inProcess={getTileReportRequest.inProcess}
        error={getTileReportRequest.error}
        errorMessage="Could not load the report for this tile"
      />
      {access &&
        getTileReportRequest.response &&
        !getTileReportRequest.inProcess &&
        !getTileReportRequest.error && (
          <div className="report-display-content">
            <PowerBIEmbed
              embedConfig={{
                type: 'report',
                id: getTileReportRequest.response.id,
                embedUrl: getTileReportRequest.response.embedUrl,
                accessToken: access,
                tokenType: models.TokenType.Aad,
              }}
            />
          </div>
        )}
    </div>
  );
};

ReportDisplay.propTypes = {
  tileId: string.isRequired,
};

export default ReportDisplay;
