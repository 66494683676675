/* eslint-disable */
import React from 'react';
import Button from 'common/components/Buttons/Button';
import './styles.scss';

const Option = ({ actionLabel = 'Select', className, label,  onClick, value })=> {
  const handleClick = () => {
    onClick(value);
  };

  return (
    <div className={className}>
      <div className="d-flex align-items-center bg-light p-1">
        <div className="option-label">{label}</div>
        <div>
          <Button
            onClick={handleClick}
            variant="warning"
            className="mr-2"
            size="sm"
            value={value}
          >
            {actionLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Option;
