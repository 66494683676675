/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { MS_REQUESTS, addLoginEvent } from 'Config/MSALClient';
import routerMap from 'app/Main/RouterMap';
import fromQS from 'common/utils/fromQS';

function SignIn() {
  const { instance } = useMsal();
  const history = useHistory();
  useEffect(() => {
    addLoginEvent(() => {
      const redirectUrl = fromQS('location')
        .replace('<query>', '?')
        .replace('<and>', '&')
        .replace('BASE', '/');
      history.push(redirectUrl);
    });
    instance.loginPopup(MS_REQUESTS.POWER_BI);
  }, []);
  return null;
}

export default SignIn;
