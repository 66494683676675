/*eslint-disable*/
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import PrivateRouteFactory from 'common/components/Routes/PrivateRoute';
import Home from 'app/Pages/Home';
import SignIn from 'app/Pages/OAuth/SignIn';
import Redirect from 'app/Pages/OAuth/Redirect';
import ReportsConfig from 'app/Pages/ReportsConfig';

import routerMap from './RouterMap';
import './styles.scss';

const PrivateRoute = PrivateRouteFactory(routerMap.signIn.path);

const Main = () => {
  return (
    <div className="App">
      <div className="app-header p-2">
        Power BI - Reports
      </div>
      <Router>
        <Switch>
          <PrivateRoute exact path={routerMap.home.path} component={Home} />
          <PrivateRoute exact path={routerMap.config.path} component={ReportsConfig} />
          <Route exact path={routerMap.signIn.path} component={SignIn} />
          <Route exact path={routerMap.oauthRedirect.path} component={Redirect} />
        </Switch>
      </Router>
    </div>
  );
}

export default Main;
