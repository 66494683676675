import ausppClient from 'Config/AusppClient';
import msalClient from 'Config/MSALClient';

const url = (workspaceId, reportId) =>
  `https://api.powerbi.com/v1.0/myorg/groups/${workspaceId}/reports/${reportId}`;

async function GetReportForTile({ tileId }, responseHandlers) {
  try {
    const ausppResponse = await ausppClient(`reports/${tileId}`);
    // eslint-disable-next-line camelcase
    const { workspace_id, report_id } = ausppResponse;
    const response = await msalClient(url(workspace_id, report_id));
    responseHandlers.onSuccess(response);
  } catch (error) {
    responseHandlers.onError(error);
  }
}

export default GetReportForTile;
