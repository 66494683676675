import ausppClient from 'Config/AusppClient';

async function AddTileReport(tileReport, responseHandlers) {
  try {
    const response = await ausppClient('reports', {
      method: 'POST',
      body: tileReport,
    });

    const mapped = response;

    responseHandlers.onSuccess({
      data: mapped.plugin,
    });
  } catch (error) {
    responseHandlers.onError(error);
  }
}

export default AddTileReport;
