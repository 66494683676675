/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { any, bool, string } from 'prop-types';
import LoadingSpinner from '../LoadingSpinner';
import ActionMessage from '../ActionMessage';

function RequestStatus({ errorMessage, inProcess, error }) {
  if (inProcess) return <LoadingSpinner />;
  if (error) return <ActionMessage message={errorMessage} />;
  return null;
}

RequestStatus.propTypes = {
  error: any,
  errorMessage: string,
  inProcess: bool,
};

RequestStatus.defaultProps = {
  error: null,
  errorMessage: 'Something went wrong',
  inProcess: false,
};

export default RequestStatus;
