import React, { useEffect } from 'react';
import { func } from 'prop-types';

import GetWorskpaces from 'app/Services/PowerBI/Workspaces/get';
import { useRequest } from 'common/utils/Request';
import Option from 'common/components/Option';
import RequestStatus from 'common/components/RequestStatus';
import ActionMessage from 'common/components/ActionMessage';

const Workspace = ({ onSelect }) => {
  const workspaceRequest = useRequest(GetWorskpaces);
  useEffect(() => {
    workspaceRequest.start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="p-2">
        <h5 className="mb-3">Select workspace</h5>
        <div>
          <RequestStatus
            inProcess={workspaceRequest.inProcess}
            error={workspaceRequest.error}
          />
          {workspaceRequest.response &&
            !workspaceRequest.inProcess &&
            !workspaceRequest.error && (
              <div className="px-2">
                {workspaceRequest.response.length ? (
                  workspaceRequest.response.map((workspace) => (
                    <Option
                      className="my-1"
                      key={workspace.id}
                      onClick={onSelect}
                      label={workspace.name}
                      value={workspace}
                      actionLabel="Reports"
                    />
                  ))
                ) : (
                  <ActionMessage message="There are no workspaces in your organization" />
                )}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

Workspace.propTypes = {
  onSelect: func.isRequired,
};

export default Workspace;
