/* eslint-disable */
import React from 'react';
import { func } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
// import routerMap from 'app/RouterMap';

function PrivateRouteHoC(signInPath) {
  function PrivateRoute({ component: Component, ...rest }) {
    const isAuthenticated = useIsAuthenticated();
    const getRoute = (props) => {
      if (!isAuthenticated) {
        const params = window.location.search
          ? window.location.search
              .replace('?', '<query>')
              .replace('&', '<and>')
          : '';

        const npath = window.location.pathname.replace('/', '');
        const path = npath || 'BASE';
        const afterLocation = path + params;
        const fullURL = `${signInPath}?location=${afterLocation}`;
        return <Redirect to={fullURL} />;
      }
      return <Component {...props} />;
    };

    return <Route {...rest} render={(props) => getRoute(props)} />;
  }

  PrivateRoute.propTypes = {
    component: func.isRequired,
  };

  PrivateRoute.defaultProps = {
  };

  return PrivateRoute;
}
export default PrivateRouteHoC;
