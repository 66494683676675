import React from 'react';

import ReportDisplay from 'app/Views/ReportDisplay';
import fromQS from 'common/utils/fromQS';

const Home = () => {
  const tileId = fromQS('tile_id');
  return <ReportDisplay tileId={tileId} />;
};

export default Home;
