import React, { useEffect } from 'react';
import { func, string } from 'prop-types';

import { useRequest } from 'common/utils/Request';
import Option from 'common/components/Option';
import GetReports from 'app/Services/PowerBI/Workspaces/getReports';
import Button from 'common/components/Buttons/Button';
import { ChevronLeft } from 'common/icons';
import ActionMessage from 'common/components/ActionMessage';
import RequestStatus from 'common/components/RequestStatus';

const ReportSelect = ({
  cleanWorskpace,
  onSelect,
  workspaceId,
  workspaceName,
}) => {
  const reportsRequest = useRequest(GetReports);

  useEffect(() => {
    reportsRequest.start(workspaceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="p-2">
        <div className="d-flex align-items-center mb-3">
          <Button
            onClick={cleanWorskpace}
            variant="dark"
            size="sm"
            className="mr-2"
          >
            <ChevronLeft />
          </Button>
          <h5 className="mb-0">Select report from {workspaceName}</h5>
        </div>
      </div>
      <RequestStatus
        inProcess={reportsRequest.inProcess}
        error={reportsRequest.error}
      />
      {reportsRequest.response &&
        !reportsRequest.inProcess &&
        !reportsRequest.error && (
          <div className="px-2">
            {reportsRequest.response.length ? (
              reportsRequest.response.map((report) => (
                <Option
                  className="my-1"
                  key={report.id}
                  label={report.name}
                  actionLabel="Select"
                  value={report}
                  onClick={onSelect}
                />
              ))
            ) : (
              <ActionMessage message="There are no reports on this workspace" />
            )}
          </div>
        )}
    </div>
  );
};

ReportSelect.propTypes = {
  cleanWorskpace: func.isRequired,
  onSelect: func.isRequired,
  workspaceId: string.isRequired,
  workspaceName: string.isRequired,
};

export default ReportSelect;
