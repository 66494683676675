/* eslint-disable default-case */
import {
  PublicClientApplication,
  LogLevel,
  InteractionRequiredAuthError,
  EventType,
} from '@azure/msal-browser';
import http from 'common/utils/Http';
import config from './index';
// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: config.get('clientId'),
    redirectUri: config.get('redirectUri'),
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
        }
      },
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const MS_REQUESTS = {
  POWER_BI: {
    scopes: [
      'https://analysis.windows.net/powerbi/api/Workspace.Read.All',
      'https://analysis.windows.net/powerbi/api/Report.Read.All',
    ],
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

let loginEvent = () => null;
let accessToken = null;

export const getAccessToken = () => accessToken;
export const addLoginEvent = (cb) => {
  loginEvent = cb;
};

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
    event?.payload?.account
  ) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
    if (!accessToken) loginEvent(account);
    accessToken = event.payload.accessToken;
  }
});

export default async function apiClient(url) {
  try {
    const {
      tokenType,
      accessToken: responseAToken,
    } = await msalInstance.acquireTokenSilent(MS_REQUESTS.POWER_BI);
    accessToken = responseAToken;
    const response = await http(url, {
      headers: {
        Accept: 'application/json;odata.metadata=minimal;',
        Authorization: `${tokenType} ${responseAToken}`,
      },
    });
    return response;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      return msalInstance.acquireTokenPopup(MS_REQUESTS.POWER_BI);
    }
  }
  return {};
}
