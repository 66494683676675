import React from 'react';
import { string } from 'prop-types';

function ActionMessage({ message }) {
  return (
    <div className="p-2 bg-dark">
      <h4 className="text-warning">{message}</h4>
    </div>
  );
}

ActionMessage.propTypes = {
  message: string.isRequired,
};

export default ActionMessage;
