import React, { useState } from 'react';
import { useRequest } from 'common/utils/Request';
import fromQS from 'common/utils/fromQS';
import AddTileReport from 'app/Services/TileReport/add';
import PostConfigCompleted from 'app/Services/Events/PostConfigCompleted';
import ReportsSelect from 'app/Views/ReportSelect';
import WorkspaceSelect from 'app/Views/WorkspaceSelect';
import RequestStatus from 'common/components/RequestStatus';

const ReportsConfig = () => {
  const [workspace, setWorkspace] = useState(null);
  const tileId = fromQS('tile_id');
  const addTileReport = useRequest(AddTileReport, {
    onSuccess: PostConfigCompleted,
    onError: () => {},
  });

  const handleOnSelectWorkspace = (selectedWorskpace) => {
    setWorkspace(selectedWorskpace);
  };

  const handleClearWorkspace = () => setWorkspace(null);

  const handleOnSelectReport = (report) => {
    addTileReport.start({
      tile_id: tileId,
      report_id: report.id,
      workspace_id: workspace.id,
    });
  };

  return (
    <div>
      <RequestStatus
        inProcess={addTileReport.inProcess}
        error={addTileReport.error}
        errorMessage="Could not add report to tile"
      />
      {workspace && !addTileReport.inProcess && !addTileReport.error && (
        <ReportsSelect
          onSelect={handleOnSelectReport}
          cleanWorskpace={handleClearWorkspace}
          workspaceId={workspace.id}
          workspaceName={workspace.name}
        />
      )}
      {!workspace && !addTileReport.inProcess && !addTileReport.error && (
        <WorkspaceSelect onSelect={handleOnSelectWorkspace} />
      )}
    </div>
  );
};

export default ReportsConfig;
